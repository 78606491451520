import React from "react";
import Image from "components/image";
import { Link } from "gatsby";

const videoThumbnail = ({title, duration, actionText,action, author}) => {

  return (
    <div onClick={action} className="rounded-3xl bg-primary text-white w-auto p-5 flex gap-6 relative hover:shadow-2xl transform transition duration-200 cursor-pointer">
      <div className="rounded-full ring-indigo-200 ring-4 ring-w-14 h-14 bg-primary hover:scale-110 transform transition duration-200 cursor-pointer">
        <svg className="h-14 w-14 text-indigo-500" fill="currentColor" viewBox="0 0 84 84">
          <path
            fill="white"
            d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z"></path>
        </svg>
      </div>
      <div className="absolute left-0 top-0 -mt-5 w-full">
        <div className="flex justify-center">
          <Image filename="laurent" className="ring-1 ring-primary rounded-full w-10"></Image>
        </div>
      </div>
      <div className="flex items-center flex-col justify-center text-sm ">
        <p className="mb-2"><span>{ title }</span></p>
        <p><span className="mr-4 opacity-75">{ duration }</span><span className="font-bold">{actionText}</span></p>
      </div>
    </div>
  )
}

export default videoThumbnail;

import React from 'react'
import { Link as LinkBase } from "gatsby"
import styled from 'styled-components'
import {theme} from '../../../../tailwind.config'
import tw from "tailwind.macro"

const Link = (props) => {
  let { color, arrow, children, underline } = props
  let SlinkTag = null;

  // Use gatsdby Link when to attribute is defined
  if (props.to) {
    SlinkTag = LinkBase
  } else {
    SlinkTag = 'a'
  }

  color = (color && color === 'light') ? 'white' : theme.extend.colors.primary
  let SArrow = styled.span`
    display: block;
    position: absolute;
    top: -4px;
    transition: 75ms linear;
  `
  let SLink  = styled(SlinkTag)`
    padding-bottom: 2px;
    position: relative;
    color: ${color};
    
    &:hover {
      color: ${color}
    }
  `

  let SLinkContent = styled.span`
    display: inherit;
    position: relative;
    ${tw`
      font-medium
    `}
    &:after {
      content: '';
      border-bottom: 1px solid ${color};
      width: 100%;
      left: 0;
      display: block;
      position: absolute;
      display: ${ (underline === false) ? 'none' : 'block' };
    }
  `;

  // Arrows
  let SArrowLeftElt = null;
  let SArrowRightElt = null;

  if (arrow === 'right') {
    SLink = styled(SLink)`
      ${tw`
        text-base
      `}
      &:hover ${SArrow} {
        right: -25px;
      }
    `
    let SArrowRight = styled(SArrow)`
      right: -20px;
    `
    SArrowRightElt = () => (<SArrowRight><i className="fa fa-arrow-right text-xs"></i></SArrowRight>);
  } else if (arrow === 'left') {
    SLink = styled(SLink)`
      &:hover ${SArrow} {
        left: -5px;
      }
    `
    let SArrowLeft = styled(SArrow)`
      left: 0px;
    `
    SLinkContent = styled(SLinkContent)`
      margin-left: 20px;
    `
    SArrowLeftElt = () => (<SArrowLeft><i className="fa fa-arrow-left text-xs"></i></SArrowLeft>);
  }

  return (<SLink {...props}>{ (SArrowLeftElt && <SArrowLeftElt/>) }<SLinkContent>{children}</SLinkContent>{ (SArrowRightElt && <SArrowRightElt/>) }</SLink>)
}

export default Link

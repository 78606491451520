import React from 'react'
import { Button, Typography } from 'components/ui'
import { signupUrl } from 'utils/urlHelper'
import styled from 'styled-components'

const SignupButton = ({color, size, urlAttr, className, text, noSubtitle}) => {
  urlAttr = (!urlAttr) ? {sb_source: "website"} : urlAttr;
  color = (!color) ? 'primary' : color;
  size = (!size) ? 'medium' : size;
  text = (text) ? text : 'Secure your backups';

  const SSubTitle = styled(Typography)`
    font-size: 0.90rem !important;
    line-height: 1.5rem;
    opacity: 0.7;
  `

  let subtitle = null;
  if (! noSubtitle) {
    subtitle = (<SSubTitle tag="p" className="mt-2 hidden sm:block">
        Free 7-day trial. 30-day money-back guarantee.</SSubTitle>)
  }

  return (
    <>
      <Button color={color} size={size} href={signupUrl(urlAttr)} className={className}>
        { text }
      </Button>
      { subtitle }
    </>
  )
}

export default SignupButton

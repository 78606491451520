import React from 'react'
import tw from "tailwind.macro"
import styled from 'styled-components'

const Typography = ({variant, tag, size, className, children}) => {
  let STag = null;
  size = (! size) ? tag : size;

  if (size === 'h1') {
    const Sh1 = styled[tag]`
      ${tw`
        font-extrabold mb-5
      `}
      font-size: 4rem;
      letter-spacing: -0.8px;
      > p {
        ${tw`mt-4`}
        letter-spacing: 0;
        font-weight: normal;
      }
    `;
    return (<Sh1 className={className}>{children}</Sh1>)
  }
  else if (size === 'h2') {
    const Sh2 = styled[tag]`
      ${tw`
        text-3.5xl leading-tight md:text-4xl md:leading-snug font-extrabold mb-6
      `}
`;
    return (<Sh2 className={className}>{children}</Sh2>)
  }
  else if (size === 'h3') {
    const Sh3 = styled[tag]`
      ${tw`
        text-2xl font-medium mb-2 mt-2 leading-snug
      `}
    `;
    return (<Sh3 className={className}>{children}</Sh3>)
  }
  else if (size === 'p') {
    switch(variant) {
      case 'xlarge':
        STag  = styled[tag]`
          ${tw`text-xl leading-relaxed`}
        `
        break
      case 'large':
          STag  = styled[tag]`
            ${tw`text-lg`}
          `
          break
      default:
        STag  = styled[tag]`
        `
    }

    return (<STag className={className}>{children}</STag>)
  }

  // By default use p tag
  return (<p className={className}>{children}</p>)

}

export default Typography

import React from "react";
import logoImage from "images/logo.svg";
import { Link } from "gatsby";
import { Container } from "components/ui";
import { supportUrl } from "../../utils/urlHelper";
import Image from "../image";

const Footer = () => {
  return (
    <footer className="white text-sm pt-20 pb-6">
      <Container>
        <img src={logoImage} alt="SimpleBackups - Files, databases and snapshots" className="mb-3 w-40"/>
        <div className="grid md:grid-cols-5 gap-6">
          <div>
            <div className="mb-2 flex items-center">
              <i className="fas fa-envelope mr-3 text-sm"></i>
              <a
                href="mailto:hello@simplebackups.com?subject=Hi!"
                target="_blank"
                rel="noopener noreferrer"
                className="no-style"
              >
                hello@simplebackups.com
              </a>
            </div>

            <div className="mt-6 leading-6 opacity-75">
              Based in <span>🇧🇪</span>, the heart of <span>🇪🇺</span>.<br/>
            </div>

            <div className="flex mt-6">
              <div className=" w-32 ">
                <Image filename="gdpr-compliant"></Image>
              </div>
              <div className="w-20 ">
                <Image filename="iso27001-vertical"></Image>
              </div>
            </div>
          </div>
          <div className="">
            <div className="text-xl font-medium">Our Solutions</div>
            <div className="bd-highlight">
              <ul className="leading-7 mt-2">
                <li><Link to="/server-backup/" className="footer-link">Server Backups</Link></li>
                <li><Link to="/mysql-backup/" className="footer-link">MySQL Backups</Link></li>
                <li><Link to="/mongodb-backup/" className="footer-link">MongoDB Backups</Link></li>
                <li><Link to="/postgresql-backup/" className="footer-link">PostgreSQL Backups</Link></li>
                <li><Link to="/redis-backup/" className="footer-link">Redis Backups</Link></li>
                <li><Link to="/snapshots/" className="footer-link">Server Snapshots</Link></li>
                <li><Link to="/storage-backup/" className="footer-link">Storage Backups</Link></li>
                <li><Link to="/saas-backup/notion" className="footer-link">Notion Backups<span
                  className="ml-1 text-2xs bg-green text-white uppercase rounded-md p-1">new</span></Link></li>
                <li><Link to="/saas-backup/knack" className="footer-link">Knack Backups</Link></li>
                <li><Link to="/saas-backup/gitea" className="footer-link">Gitea Backups</Link></li>
                <li><Link to="/saas-backup/github" className="footer-link">Github Backups</Link></li>
                <li><Link to="/saas-backup/gitlab" className="footer-link">GitLab Backups</Link></li>
              </ul>
            </div>
          </div>
          <div className="">
            <div className="text-xl font-medium">Solutions</div>
            <div className="bd-highlight">
              <ul className="leading-7 mt-2">
                <li><Link to="/digitalocean-backup/" className="footer-link">DigitalOcean Backups</Link></li>
                <li><Link to="/agency-backup-solution/" className="footer-link">Web agencies backup solution</Link></li>
                <li><Link to="/saas-backup-solution/" className="footer-link">SaaS backup solution</Link></li>
                <li><Link to="/features/" className="nav-link">All Features</Link></li>
                <li><Link to="/catalog/" className="footer-link">Integration Catalog</Link></li>
              </ul>
            </div>
          </div>
          <div className="">
            <div className="text-xl font-medium">Company</div>
            <div className="bd-highlight">
              <ul className="leading-7 mt-2">
                <li><Link to="/contact-us/" className="footer-link">Contact Us</Link></li>
                <li><Link to="/affiliate-program/" className="footer-link">Become an affiliate</Link></li>
                <li><Link to="/case-study/" className="footer-link">What our users say</Link></li>
              </ul>
            </div>
            <div className="text-xl font-medium mt-6">Resources</div>
            <div className="bd-highlight">
              <ul className="leading-7 mt-2">
                <li><a href="/blog/" className="footer-link">Blog</a></li>
                <li><a href="https://docs.simplebackups.com" className="footer-link" target="_blank">Documentation</a></li>
                <li><a href="https://simplebackups.docs.apiary.io" target="_blank" rel="noopener noreferrer" className="footer-link">API Documentation</a></li>
                <li><a href={supportUrl()} className="footer-link">FAQ</a></li>
                <li><a href="https://simplebackups.instatus.com/" target="_blank" rel="noopener noreferrer" className="footer-link">System Status</a></li>
                <li><Link to="/security-first" className="footer-link">Security, Privacy & Compliance</Link></li>
              </ul>
            </div>
          </div>
          <div>
            <div className="text-xl font-medium">Favorite things</div>
            <div className="bd-highlight">
              <div>
                <ul className="leading-7 mt-2">
                  <li className="flex items-center"><a target="" href="/tools/cloud-providers-regions/"
                                                       className="footer-link">Cloud Provider Regions Index <span
                    className="ml-1 text-2xs bg-green text-white uppercase rounded-md p-1">new</span></a></li>
                  <li><a target="_blank" href="https://simplerestore.io/"
                        className="footer-link">MySQL backup recovery service</a></li>
                  <li><a target="" href="/blog/the-complete-mongodump-guide-with-examples/"
                         className="footer-link">MongoDump Guide with Examples</a></li>
                  <li><a target="" href="/blog/the-complete-mysqldump-guide-with-examples/"
                         className="footer-link">MySQLDump Guide with Examples</a></li>
                  <li><a target="" href="/blog/the-ultimate-mysql-database-backup-script/"
                         className="footer-link">MySQL Database Backup Script</a></li>
                  <li><a target="" href="/blog/the-ultimate-mongodb-database-backup-script/"
                         className="footer-link">MongoDB Database Backup Script</a></li>
                  <li><a target="" href="/blog/postgresql-pgdump-and-pgrestore-guide-examples/"
                         className="footer-link">PostgreSQL PGDump Guide</a></li>
                  <li><a target="" href="/blog/the-ultimate-postgresql-database-backup-script/"
                         className="footer-link">PostgreSQL Database Backup Script</a></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-grid mt-4">
          <div className="col-md-12 md:flex items-center space-x-2">
            <p>Copyright {new Date().getFullYear()} SimpleBackups <span className="hidden md:inline mr-6">-</span></p>
            <div className="flex space-x-6">
            <Link to="/terms-and-conditions/" className="-ml-2 footer-link">Terms</Link>
            <Link to="/privacy/" className="footer-link">Privacy</Link>
            <Link to="/cookie-policy/" className="footer-link">Cookie Policy</Link>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer

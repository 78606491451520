import React from "react"
import Tippy from "@tippy.js/react"
import '../../styles/tippy.css';
import styled from 'styled-components'

const TippyText = styled.span`
  border-bottom: 1px dotted #525F7E;
  cursor: help;
`;

const StyledTippy = styled(Tippy)`
  text-align: center;
`;

const StyledPointer = styled.div`
  cursor: help;
`

export const Popover = ({ content, styled, children, className }) => {
  if (styled === false) return <StyledTippy content={content} arrow={true}><StyledPointer>{children}</StyledPointer></StyledTippy>
  else return <StyledTippy content={content} arrow={true}><TippyText className={className}>{children}</TippyText></StyledTippy>
}

export default Popover

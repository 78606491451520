import {Generic, GenericCollection, JSONLD, Question} from "react-structured-data";
import React from "react";

 const renderJsonLd = (faqList) => {

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic type="FAQPage" jsonldtype="FAQPage">
        <GenericCollection type="mainEntity">
          { faqList.map(({title, content}, index) => (
            <Question name={title} key={index} >
              <Generic type="acceptedAnswer" jsonldtype="Answer" schema={{ text: content}}></Generic>
            </Question>
          ) )}
        </GenericCollection>
      </Generic>
    </JSONLD>
  )
}

export default renderJsonLd;
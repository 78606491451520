import React, { useState } from 'react'

// FAQ List / Container
const Faqs = (props) => {
  let { items } = props

  return (
    <>
    <div {...props} className="grid grid-cols-1 mt-4 divide-y divide-gray-100">
      { items.map((element, index) => (<Faq key={index} faq={element}/>) )}
    </div>
    </>
  )
}

// Individual FAQ Component
const Faq = (props) => {
  const {title, content} = props.faq;
  const [open, setOpen] = useState(false);

  let icon = (open) ? (<i className="fa fa-minus"></i>) : (<i className="fa fa-plus"></i>);

  return (
    <div className="py-4 cursor-pointer" onClick={() => setOpen(!open)}>
      <span className="text-sm mr-2">{icon}</span>
      <span className="font-bold">{title}</span>
      {open && (<p className="px-4 pt-2">{content}</p>)}
    </div>
  )
}

export default Faqs

import React from 'react'
import classnames from "classnames";
import * as Ui from "components/ui";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dotsBg from "images/slider-dots-bg.png";

export default (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
    <>
      {( props.items && (
      <div className="text-left relative">
        <div className="absolute" style={
          {'backgroundImage': 'url('+dotsBg+')',
            'width': '100%',
            'height': '84px',
            'backgroundSize': '200%',
            'top': 'calc(50% - 42px)',
          }
        }></div>
        <Slider {...settings} className="" >
          {props.items.map((item, index) => (
            <div className="p-4 z-0 relative">
            <div key={index} className="p-6 bg-white rounded-lg shadow-lg ">
              <div className="mb-6">{item.image}</div>
              <Ui.Typography tag="h3" className="">{item.title}</Ui.Typography>
              <p className="text-base my-4 opacity-75">{item.text}</p>
              {item.url && (<Ui.Link className="" arrow="right" underline={false} to={item.url}>Learn more</Ui.Link>)}
            </div>
            </div>
            ))}
        </Slider>
      </div>
      ))}
    </>
  )
};


const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className="absolute -right-2.5 top-1/2 rounded-xl bg-white shadow-lg p-4 cursor-pointer  z-10"
      onClick={onClick}
    ><i className="fa fa-angle-right "/></div>
  );
}

const PrevArrow = (props) =>{
  const { className, style, onClick } = props;
  return (
    <div
      className="absolute -left-2.5 top-1/2 rounded-xl bg-white shadow-lg p-4 cursor-pointer z-10"
      onClick={onClick}
    ><i className="fa fa-angle-left "/></div>
  );
}

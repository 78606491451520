import React from 'react'
import classnames from "classnames";

export default (props) => {
  let cols = (props.cols) ? props.cols : 2;
  return (
    <>
      {( props.items && (<ul className={`my-6 grid grid-cols-${cols}`}>{
        props.items.map((text, index) => (
          <li key={index} className="flex items-center text-sm mb-2 flex-nowrap">
            <i className="far fa-check-circle text-green mr-2"></i>
            <span className="font-medium">{text}</span>
          </li>
        ))
      }</ul>))}
    </>
  )
};



import React from "react"
import classnames from "classnames";
import {Typography} from "components/ui"

const SectionTitle = ({tag, title, subTitle, align, className}) => {
  const DynamicTag = `${tag}`;

  return (
    <div className={classnames({
      className,
      'text-center': align === 'center',
      'md:w-4/5 m-auto': align === 'center' && tag != "h1",
      'mb-10': true,
    })}>
      <h1 className={classnames({
        'text-dark font-heading tracking-tight font-black leading-tight text-4xl md:text-5xl ': true,
      })}>{title}</h1>
      { subTitle && (<p className="text-lg leading-relaxed color-gray-400 font-normal mt-4 md:text-xl">{subTitle}</p>)}
    </div>
  )
}

export default SectionTitle

import React from "react";

const TocNav = (props)=> {
  const { post } = props
  return (
    <nav className="text-sm list-none my-2">
      {post.items.map(p => (
        <li key={p.url} className="mb-2">
          <a href={p.url}><i className="fal fa-angle-right opacity-75 text-xs mr-2 hover:text-primary"></i> {p.title}</a>
          { p.items && <div className="ml-2"><TocNav post={p}></TocNav></div> }
        </li>
      ))}
    </nav>)
}

const Toc = (props) => {
  const { post, title } = props
  return (
    <div className="sticky top-3">
      <div className="uppercase text-xs font-bold mb-4 pt-4">Table of contents</div>
      { !post.items && <div className="text-sm"><i className="fal fa-angle-right opacity-75 text-xs mr-2 hover:text-primary"></i>{title}</div>}
      {post.items && <TocNav post={post}></TocNav>}
    </div>
  )
}

export default Toc;

import React from 'react'
import Image from "components/image"

const Testimonial = (props) => {
  let {avatar, name, role, company, content, color} = props;

  if (color === 'alt') {
    return (
      <div className="p-6 rounded-xl text-white p-6 bg-white bg-opacity-10 md:w-1/2">
        <p className="text-base text-left ml-8">
          {content}
        </p>
        <div className="flex items-center">
          <div className="flex"><Image filename={avatar} className="rounded-2xl  block " style={{'width' : '90px', 'height': '90px'}}/></div>
          <p className="mt-4 font-bold">{name}<span className="text-white ml-1 text-sm font-normal">- {role}</span></p>
        </div>
      </div>
    )
  }
  avatar = (avatar) ?  avatar : 'testimonials-default.png';

  return (
    <div className="rounded-xl bg-gray-50 p-8 flex flex-col justify-between text-left">
      <p className="text-lg" dangerouslySetInnerHTML={{ __html: content }}></p>

      <div className="flex items-center mt-6 gap-6">
        <div className="flex">
          <Image filename={(avatar) ? avatar : 'testimonials-default.png'} className="rounded-full w-14 h-14 block"/>
        </div>
        <div className="">
          <p className="font-bold text-lg tracking-snug">{name}</p>
          <p className="text-primary text-base">
          {role && (<>{role} </>)}
          {company && (<>{company}</>)}
          </p>
        </div>
      </div>
    </div>
  )

}

export default Testimonial

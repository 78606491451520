import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { extension: { in: ["jpg","jpeg", "png", "gif"] } }){
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, PNG],
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }

      const imageFluid = getImage(image.node);
      return <GatsbyImage alt={props.alt} image={imageFluid} {...props} />
    }}
  />
)
export default Image

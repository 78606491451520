import React, { Fragment, useRef, useState } from "react";
import { Link } from "gatsby";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { loginUrl, signupUrl } from "../../../utils/urlHelper";
import * as Ui from "components/ui";
import logoImage from "images/logo.svg";
import logoImageWhite from "images/logo-white.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example(props) {
  let { logoColor, textColor } = props;
  let menuTextClassDefault = null;
  let menuTextClassActive = null;

  if (textColor === "white") {
    menuTextClassDefault = "text-white";
    menuTextClassActive = "text-white";
  } else {
    menuTextClassDefault = "text-dark";
    menuTextClassActive = "text-dark";
  }

  // TODO: currently not used - Was written for HOVER effect, but there is a conflict with gatsbyImage in menu
  // triggering an mouseout on the container
  const buttonRef = useRef(null);
  const [openState, setOpenState] = useState(false);
  const [mobileOpenedMenu, setMobileOpenedMenu] = useState(null);
  const transitions = Object.freeze({
    firstOpen: {
      enter: "transition ease-out duration-200",
      enterFrom: "opacity-0 translate-y-1",
      enterTo: "opacity-100 translate-y-0",
      leave: "transition ease-in duration-150",
      leaveFrom: "opacity-100 translate-y-0",
      leaveTo: "opacity-0 translate-y-1",
    },
    fromLeft: {
      enter: "transition ease-out duration-200",
      enterFrom: "opacity-0 translate-x-1",
      enterTo: "opacity-100 translate-x-0",
      leave: "transition ease-in duration-150",
      leaveFrom: "opacity-100 translate-x-0",
      leaveTo: "opacity-0 translate-x-1",
    },
    fromRight: {
      enter: "transition ease-out duration-200",
      enterFrom: "opacity-0 translate-x-1",
      enterTo: "opacity-100 translate-x-0",
      leave: "transition ease-in duration-150",
      leaveFrom: "opacity-100 translate-x-0",
      leaveTo: "opacity-0 translate-x-1",
    },
  });
  const [transitionProperties, setTransitionProperties] = useState(
    transitions.firstOpen
  );

  const toggleMobileOpenedMenu = (e, menu) => {
    e.preventDefault();
    if (mobileOpenedMenu === menu) {
      setMobileOpenedMenu(null);
    } else {
      setMobileOpenedMenu(menu);
    }
  };

  const [currentTarget, setCurrentTarget] = useState(null);

  const handleMenuItemMouseEnter = (e) => {
    if (currentTarget === e.currentTarget) return;
    // TODO: Add swipe animation
    // const prevNum = currentTarget?.id?.match(/\d+/)[0];
    // const currentNum = e.currentTarget.id.match(/\d+/)[0];
    // if (prevNum && prevNum < currentNum) setTransitionProperties(transitions.fromRight);
    // if (prevNum && prevNum > currentNum) setTransitionProperties(transitions.fromLeft);
    setCurrentTarget(e.currentTarget);
    e.currentTarget.click();
  };

  const popoverRef = useRef(null);

  const handleMenuMouseLeave = (e) => {
    if (currentTarget === null || e.relatedTarget.tagName == "IMG") return;
    setTransitionProperties(transitions.firstOpen);
    currentTarget.click();
    setCurrentTarget(null);
  };

  return (
    <Popover
      className="relative"
      ref={popoverRef}
      onMouseLeave={handleMenuMouseLeave}
    >
      <div className="flex justify-between items-center py-6 lg:justify-start lg:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1 gap-12">
          <div>
            <Link to="/">
              <span className="sr-only">SimpleBackups</span>
              <img
                src={logoColor === "white" ? logoImageWhite : logoImage}
                alt="SimpleBackups"
              />
            </Link>
          </div>
          {/* Main Menu */}
          <Popover.Group
            as="nav"
            className="hidden lg:flex lg:space-x-10 md:space-x-8 items-center"
          >
            {/* Product menu */}
            <Popover className="relative flex">
              {({ open }) => (
                <>
                  <Popover.Button
                    id="menu-item-1"
                    onMouseEnter={handleMenuItemMouseEnter}
                    className={classNames(
                      open ? menuTextClassDefault : menuTextClassActive,
                      `group rounded-md inline-flex items-center text-sm font-medium hover:${menuTextClassDefault} focus:outline-none`
                    )}
                  >
                    <span>Product</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? menuTextClassDefault : menuTextClassActive,
                        `ml-2 h-5 w-5 group-hover:${menuTextClassDefault}`
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition as={Fragment} {...transitionProperties}>
                    <Popover.Panel className="absolute z-10 -ml-4 px-8 pb-8 box-content mt-8 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden bg-white">
                        <div className="p-5 sm:p-8 ">
                          <div className="flex items-center">
                            <div className="text-base font-medium text-gray-600 flex items-center">
                              <div>Database Backups</div>
                            </div>
                          </div>
                          <ul className="grid grid-cols-2 gap-2 text-sm mt-3">
                            <li>
                              <Link
                                to="/mysql-backup/"
                                className="nav-link flex items center"
                              >
                                <div className="w-6 flex mr-3">
                                  <Ui.Image
                                    filename="mysql-ico.png"
                                    alt="MySQL backup"
                                  />
                                </div>
                                <div>
                                  MySQL Backup
                                  <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/postgresql-backup/"
                                className="nav-link flex"
                              >
                                <div className="w-6 flex mr-3">
                                  <Ui.Image
                                    filename="postgresql-ico.png"
                                    alt="PostgreSQL backup"
                                  />
                                </div>
                                <div>
                                  PostgreSQL Backup
                                  <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/mongodb-backup/"
                                className="nav-link flex"
                              >
                                <div className="w-6 flex mr-3">
                                  <Ui.Image
                                    filename="mongodb-ico.png"
                                    alt="MongoDB backup"
                                  />
                                </div>
                                <div>
                                  MongoDB Backup
                                  <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/redis-backup/"
                                className="nav-link flex"
                              >
                                <div className="w-6 flex mr-3">
                                  <Ui.Image
                                    filename="redis-ico.png"
                                    alt="Redis backup"
                                  />
                                </div>
                                <div>
                                  Redis Backup
                                  <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        {/* Server menu */}
                        <div className="px-8 py-4 bg-gray-50">
                          <div className="relative grid gap-0 lg:grid-cols-2">
                            <a
                              href="/server-backup/"
                              className="flex items-center"
                            >
                              <div>
                                <p className="text-base font-medium text-gray-900">
                                  Server & File Backups
                                </p>
                              </div>
                            </a>
                            <div>
                              <div className="py-3 flex space-x-3">
                                <Link
                                  to="/server-backup/"
                                  className="flex items-center relative justify-center rounded-full bg-white overflow-hidden"
                                >
                                  <Ui.Image
                                    filename="brands/icons/ssh-ico.png"
                                    alt="SSH server backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/server-backup/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/linux-ico.png"
                                    alt="Linux server backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/server-backup/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/more-ico.png"
                                    alt="File and server backup"
                                    className="w-6"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="px-8 py-4">
                          <div className="relative grid gap-0 lg:grid-cols-2">
                            <a href="/snapshots/" className="flex items-center">
                              <div>
                                <p className="text-base font-medium text-gray-900">
                                  Server & Volume Snapshots
                                </p>
                              </div>
                            </a>
                            <div>
                              <div className="py-3 flex space-x-3">
                                <Link
                                  to="/snapshots/digitalocean"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/digitalocean-ico.png"
                                    alt="DigitalOcean snapshot"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/snapshots/vultr"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/vultr-ico.png"
                                    alt="Vultr snapshot"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/snapshots/upcloud"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/upcloud-ico.png"
                                    alt="Upcloud snapshot"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/snapshots/hetzner"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/hetzner-ico.png"
                                    alt="Hetzner snapshot"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/snapshots/exoscale"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/exoscale-ico.png"
                                    alt="Exoscale snapshot"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/snapshots/aws-ec2"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/aws-ico.png"
                                    alt="AWS EC2 snapshot"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/snapshots/amazon-lightsail"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/amazon-lightsail-ico.png"
                                    alt="Amazon Lightsail snapshot"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/snapshots/scaleway"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/scaleway-ico.png"
                                    alt="Scalway server and volume snapshot"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/snapshots/linode-akamai"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/linode-ico.png"
                                    alt="Linode instance snapshot"
                                    className="w-6"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="px-8 py-4  bg-gray-50">
                          <div className="relative grid gap-0 lg:grid-cols-2">
                            <a
                              href="/storage-backup/"
                              className="pr-3 flex items-center"
                            >
                              <div className="">
                                <p className="text-base font-medium text-gray-900">
                                  Storage Backup
                                </p>
                              </div>
                            </a>
                            <div>
                              <div className="py-3 flex space-x-3">
                                <Link
                                  to="/storage-backup/dropbox"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/dropbox-ico.png"
                                    alt="Dropbox backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/storage-backup/google-cloud-storage"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/google-cloud-ico.png"
                                    alt="Google Cloud Storage backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/storage-backup/digitalocean"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/digitalocean-ico.png"
                                    alt="DigitalOcean Spaces backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/storage-backup/wasabi"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/wasabi-ico.png"
                                    alt="Wasabi backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/storage-backup/azure"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/azure-ico.png"
                                    alt="Azure backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/storage-backup/upcloud"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/upcloud-ico.png"
                                    alt="UpCloud backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/storage-backup/aws-s3"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/aws-ico.png"
                                    alt="Amazon s3 backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/storage-backup/exoscale"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/exoscale-ico.png"
                                    alt="Exoscale backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/storage-backup/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/more-ico.png"
                                    alt="S3 storage backup"
                                    className="w-6"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="px-8 py-4">
                          <div className="relative grid gap-0 lg:grid-cols-2">
                            <a
                              href="/saas-backup/"
                              className="flex items-center"
                            >
                              <div>
                                <p className="text-base font-medium text-gray-900">
                                  SaaS Backups
                                </p>
                              </div>
                            </a>
                            <div>
                              <div className="py-3 flex space-x-3">
                                <Link
                                  to="/saas-backup/github"
                                  className="flex items-center relative justify-center rounded-full bg-white overflow-hidden"
                                >
                                  <Ui.Image
                                    filename="brands/icons/github-ico.png"
                                    alt="GitHub backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/saas-backup/knack"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/knack-ico.png"
                                    alt="Knack backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/saas-backup/gitea"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/gitea-ico.png"
                                    alt="Gitea backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/saas-backup/gitlab"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/gitlab-ico.png"
                                    alt="GitLab backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/saas-backup/notion"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/notion-ico.png"
                                    alt="Notion backup"
                                    className="w-6"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            {/* Solutions menu */}
            <Popover className="relative flex">
              {({ open }) => (
                <>
                  <Popover.Button
                    id="menu-item-2"
                    onMouseEnter={handleMenuItemMouseEnter}
                    className={classNames(
                      open ? menuTextClassDefault : menuTextClassActive,
                      `group rounded-md inline-flex items-center text-sm font-medium hover:${menuTextClassDefault} focus:outline-none`
                    )}
                  >
                    <span>Solutions</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? menuTextClassDefault : menuTextClassActive,
                        `ml-2 h-5 w-5 group-hover:${menuTextClassDefault}`
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition as={Fragment} {...transitionProperties}>
                    <Popover.Panel className="absolute z-10 -ml-4 px-8 pb-8 box-content mt-8 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden bg-white">
                        <div className="p-5 sm:p-8">
                          <a
                            href="/digitalocean-backup/"
                            className="-m-3 p-3 flex gap-x-4 items-center"
                          >
                            <Ui.Image
                              filename="menu-do-logo.png"
                              style={{ width: 26 }}
                              alt="DigitalOcean backup solution"
                            />
                            <div>
                              <div className="flex items-center">
                                <div className="text-base font-medium text-gray-900">
                                  DigitalOcean Backup Solution
                                </div>
                              </div>
                              <p className="mt-1 text-sm text-gray-500">
                                Droplet, Database and Snapshot backups
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="p-5 sm:p-8 bg-gray-50">
                          <div className="relative grid gap-0 lg:grid-cols-2">
                            <a
                              href="/database/dbaas-backup/"
                              className="-m-3 p-3 flex gap-x-4 items-center"
                            >
                              <Ui.Image
                                filename="menu-database.png"
                                style={{ width: 24 }}
                                alt="Database backup"
                              />
                              <div>
                                <div className="flex items-center">
                                  <div className="text-base font-medium text-gray-900">
                                    DBaaS Backup Solution
                                  </div>
                                </div>
                                <p className="mt-1 text-sm text-gray-500">
                                  Managed database backups
                                </p>
                              </div>
                            </a>
                            <div>
                              <div className="p-3 flex space-x-3">
                                <Link
                                  to="/database/dbaas-backup/"
                                  className="flex items-center relative justify-center rounded-full bg-white overflow-hidden"
                                >
                                  <Ui.Image
                                    filename="brands/icons/digitalocean-ico.png"
                                    alt="DigitalOcean database backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/database/aws-rds-backup/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/aws-ico.png"
                                    alt="AWS database backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/database/dbaas-backup/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/google-cloud-ico.png"
                                    alt="Google Cloud database backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/database/dbaas-backup/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/azure-ico.png"
                                    alt="Azure database backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/database/planetscale-backup/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/planetscale-ico.png"
                                    alt="Planetscale database backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/database/dbaas-backup/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/railway-ico.png"
                                    alt="Railway database backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/database/supabase/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/supabase-ico.png"
                                    alt="Supabase database backup"
                                    className="w-6"
                                  />
                                </Link>
                                <Link
                                  to="/database/neon/"
                                  className="flex items-center relative justify-center"
                                >
                                  <Ui.Image
                                    filename="brands/icons/neon-ico.png"
                                    alt="Neon database backup"
                                    className="w-6"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-5  sm:p-8">
                          <div className="flex items-center">
                            <div className="text-base font-medium text-gray-600">
                              Solving the backup equation for:
                            </div>
                          </div>
                          <ul className="grid grid-cols-3 gap-2 text-sm mt-3">
                            <li>
                              <Link to="/" className="nav-link">
                                Developers
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/agency-backup-solution/"
                                className="nav-link"
                              >
                                Digital agencies
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/saas-backup-solution/"
                                className="nav-link"
                              >
                                SaaS companies
                              </Link>
                            </li>
                          </ul>

                          <p className="text-sm mt-4">
                            <Link
                              to="/case-study/"
                              className=" text-xs uppercase text-xs text-gray-500"
                            >
                              <i className="fa fa-angle-right mr-2 text-xs"></i>
                              What they say about us
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            {/* Learn menu */}
            <Popover className="relative flex">
              {({ open }) => (
                <>
                  <Popover.Button
                    id="menu-item-3"
                    onMouseEnter={handleMenuItemMouseEnter}
                    className={classNames(
                      open ? menuTextClassDefault : menuTextClassActive,
                      `group rounded-md inline-flex items-center text-sm font-medium hover:${menuTextClassDefault} focus:outline-none`
                    )}
                  >
                    <span>Learn</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? menuTextClassDefault : menuTextClassActive,
                        `ml-2 h-5 w-5 group-hover:${menuTextClassDefault}`
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition as={Fragment} {...transitionProperties}>
                    <Popover.Panel className="absolute z-10 -ml-4 px-8 pb-8 box-content mt-8 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden bg-white">
                        <div className="px-8 py-3">
                          <div className="py-3">
                            <a
                              href="/catalog/"
                              className="-m-3 p-3 flex gap-x-4 items-center"
                            >
                              <Ui.Image
                                filename="menu-developer.png"
                                style={{ width: 26 }}
                                alt="Backup solution integration catalog"
                              />
                              <div>
                                <div className="flex items-center">
                                  <div className="text-base font-medium text-gray-900">
                                    Integrations Catalog
                                  </div>
                                </div>
                                <p className="mt-1 text-sm text-gray-500">
                                  Fully integrated with most providers and
                                  solutions
                                </p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="px-8 py-8 bg-gray-50">
                          <ul className="grid grid-cols-2 gap-2 text-sm">
                            <li>
                              <Link to="/blog/" className="nav-link">
                                Blog
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="https://docs.simplebackups.com/"
                                className="nav-link"
                              >
                                Guides & Documentation
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="https://simplebackups.docs.apiary.io/"
                                className="nav-link"
                              >
                                API Documentation
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="https://docs.simplebackups.com"
                                className="nav-link"
                              >
                                FAQs
                              </Link>
                            </li>
                            <li>
                              <Link to="/contact-us/" className="nav-link">
                                Contact us
                              </Link>
                            </li>
                          </ul>
                          <div className="flex items-center mt-6">
                            <div className="text-base font-medium text-gray-600">
                              Favorite things:
                            </div>
                          </div>
                          <ul className="grid grid-cols-2 gap-2 text-sm mt-3">
                            <li>
                              <Link
                                to="/tools/cloud-providers-regions/"
                                className="nav-link"
                              >
                                Our Cloud Provider Regions Index
                              </Link>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://simplerestore.io/"
                                className="nav-link"
                              >
                                MySQL Backup Recovery Tool
                              </a>
                            </li>
                            <li>
                              <Link
                                to="/blog/postgresql-pgdump-and-pgrestore-guide-examples/"
                                className="nav-link"
                              >
                                PostgreSQL PGDump Guide
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/blog/the-complete-mysqldump-guide-with-examples/"
                                className="nav-link"
                              >
                                MySQLDump Guide with Examples
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            {/* Pricing menu */}
            <a
              href="/pricing/"
              className={`text-sm font-medium ${menuTextClassActive} hover:${menuTextClassDefault}`}
            >
              Pricing
            </a>
          </Popover.Group>
        </div>

        <div onMouseLeave={handleMenuMouseLeave}>
          <div className="-mr-2 -my-2 lg:hidden">
            <Popover.Button
              className={`p-3 inline-flex items-center justify-center rounded-full bg-primary shadow-lg ${menuTextClassDefault} hover:${menuTextClassDefault}`}
            >
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden lg:flex items-center justify-end gap-3">
            <Ui.Button
              color="white"
              size="small"
              className="shadow-xl"
              href={signupUrl({ sb_source: "website", sb_term: "navigation" })}
            >
              Sign Up
            </Ui.Button>
            <Ui.Button
              color="primary-200"
              size="small"
              className="shadow-xl"
              href={loginUrl({ sb_source: "website", sb_term: "navigation" })}
            >
              Log in
            </Ui.Button>
          </div>
        </div>
      </div>

      {/******************************************
         Mobile  menu
         ******************************************/}
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          tabIndex="0"
          focus
          className="absolute top-0 left-0 w-full inset-x-0 z-10 transition transform origin-top-right lg:hidden"
          style={{ width: "calc(100% + 3rem)", left: "-1.5rem" }}
        >
          <div className="shadow-lg divide-y-2 divide-gray-50 bg-white ">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <Link to="/">
                  <span className="sr-only">SimpleBackups</span>
                  <img className="w-36" src={logoImage} alt="SimpleBackups" />
                </Link>
                <div className="-mr-2">
                  <Popover.Button className="p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid grid-cols-1 gap-8">
                  <ul className="flex flex-col text-lg gap-4">
                    <li className="">
                      <div
                        className="font-bold flex justify-between"
                        onClick={(e) => toggleMobileOpenedMenu(e, "product")}
                      >
                        <span>Product</span>
                        <i className="fa fa-angle-right text-sm text-gray-500"></i>
                      </div>
                      {mobileOpenedMenu === "product" && (
                        <ul className="grid grid-cols-1 gap-y-3 text-base font-normal divide-y items-center">
                          <li className="pt-3">
                            <Link
                              to="/mysql-backup/"
                              className="nav-link flex items center"
                            >
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="mysql-ico.png"
                                  alt="MySQL backup"
                                />
                              </div>
                              <div>
                                MySQL Backup
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link
                              to="/postgresql-backup/"
                              className="nav-link flex"
                            >
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="postgresql-ico.png"
                                  alt="PostgreSQL backup"
                                />
                              </div>
                              <div>
                                PostgreSQL Backup
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link
                              to="/mongodb-backup/"
                              className="nav-link flex"
                            >
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="mongodb-ico.png"
                                  alt="MongoDB backup"
                                />
                              </div>
                              <div>
                                MongoDB Backup
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link to="/redis-backup/" className="nav-link flex">
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="redis-ico.png"
                                  alt="Redis backup"
                                />
                              </div>
                              <div>
                                Redis Backup
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link
                              to="/server-backup/"
                              className="nav-link flex items-center"
                            >
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="server-p-icon.png"
                                  alt="Redis backup"
                                />
                              </div>
                              <div>
                                Server & File Backups
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link to="/snapshots/" className="nav-link flex">
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="snapshot-p-icon.png"
                                  alt="Redis backup"
                                />
                              </div>
                              <div>
                                Server & Volume Snapshots
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link
                              to="/storage-backup/"
                              className="nav-link flex"
                            >
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="storage-p-icon.png"
                                  alt="Redis backup"
                                />
                              </div>
                              <div>
                                Storage Backups
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link to="/saas-backup/" className="nav-link flex">
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="saas-p-icon.png"
                                  alt="Redis backup"
                                />
                              </div>
                              <div>
                                SaaS App Backups
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li className="">
                      <div
                        className="font-bold flex justify-between"
                        onClick={(e) => toggleMobileOpenedMenu(e, "solution")}
                      >
                        <span>Solution</span>
                        <i className="fa fa-angle-right text-sm text-gray-500"></i>
                      </div>
                      {mobileOpenedMenu === "solution" && (
                        <ul className="grid grid-cols-1 gap-y-3 text-base font-normal divide-y items-center">
                          <li className="pt-3">
                            <Link
                              to="/digitalocean-backup/"
                              className="nav-link flex"
                            >
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="menu-do-logo.png"
                                  alt="DigitalOcean backup solution"
                                />
                              </div>
                              <div>
                                DigitalOcean Backup Solution
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link
                              to="/database/dbaas-backup/"
                              className="nav-link flex"
                            >
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="menu-database.png"
                                  alt="Database backup solution"
                                />
                              </div>
                              <div>
                                DBaaS Backup Solution
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link to="/case-study/" className="nav-link flex">
                              <div className="w-6 flex mr-3">⭐️</div>
                              <div>
                                What they say about us
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li className="font-bold flex justify-between">
                      <Link to="/pricing/" className="nav-link flex">
                        Pricing
                      </Link>
                    </li>
                    <li className="">
                      <div
                        className="font-bold flex justify-between"
                        onClick={(e) => toggleMobileOpenedMenu(e, "learn")}
                      >
                        <span>Learn</span>
                        <i className="fa fa-angle-right text-sm text-gray-500"></i>
                      </div>
                      {mobileOpenedMenu === "learn" && (
                        <ul className="grid grid-cols-1 gap-y-3 text-base font-normal divide-y items-center">
                          <li className="pt-3">
                            <Link to="/blog/" className="nav-link flex">
                              <div className="w-6 flex mr-3">📚</div>
                              <div>
                                Blog
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link
                              to="https://docs.simplebackups.com"
                              className="nav-link flex"
                            >
                              <div className="w-6 flex mr-3">📖</div>
                              <div>
                                Guides & Documentation
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="pt-3">
                            <Link to="/catalog/" className="nav-link flex">
                              <div className="w-6 flex mr-3">
                                <Ui.Image
                                  filename="menu-developer.png"
                                  style={{ width: 26 }}
                                  alt="Backup solution integration catalog"
                                />
                              </div>
                              <div>
                                Integrations Catalog
                                <i className="fa fa-arrow-right ml-2 nav-link-arrow"></i>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className="py-8 px-5">
              <div className="flex flex-col gap-3">
                <Ui.Button
                  color="primary"
                  size="small"
                  className="shadow-xl w-full text-center"
                  href={signupUrl({
                    sb_source: "website",
                    sb_term: "navigation",
                  })}
                >
                  Sign Up
                </Ui.Button>
                <Ui.Button
                  color="primary-border"
                  size="small"
                  className="shadow-xl  w-full text-center"
                  href={loginUrl({
                    sb_source: "website",
                    sb_term: "navigation",
                  })}
                >
                  Log in
                </Ui.Button>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import {theme} from '../../../../tailwind.config'

const SQuote = styled.p`
  margin: 0 15%;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align:center;
  font-family: 'Lora';

  @media(max-width: ${theme.extend.screenSizes.md.max}) {
    margin: 0;
  }
`
const SAuthor = styled.div`
  tex-align:center;
  font-family: 'Muli';
  ${tw`text-base opacity-75`}
`

export default (props) => {
  const { children, author } = props;

  return (
  <SQuote>"{children}" {(author && <SAuthor>{author}</SAuthor>)}</SQuote>
  )
}

module.exports = {
  theme: {
    extend: {
      screenSizes: {
        'tablet': '640px',
        'md': {'min': '768px', 'max': '1023px'},
        'lg': {'min': '1024px', 'max': '1279px'},
        'xl': {'min': '1280px'},
        'maxsm': {'max': '640px'},
      },
      colors: {
        primary:'#6267ED',
        purple: {
          default: '#6267ED',
          200: '#5F71E9',
        },
        gray: {
          lighter: '#F8FBFF',
        },
        text:'#292D33',
        green: '#44D7D0',
        blue: '#62B2ED',
        pink: '#E200FF',
        dark: '#292D33',
        red: '#E87272',
        aubergine: '#20225D',
      },
      fontSize: {
        '2xs': '0.5rem',
        '5xl': '3.7rem',
        '4xl': '3.0rem',
        '3.5xl': '2.5rem',
      }
    }
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
  ]
}

import React from 'react'
import tw from "tailwind.macro"
import styled from 'styled-components'
import {theme} from '../../../../tailwind.config'
import { Link } from "gatsby"

const Feature = (props) => {
  const {icon, title, content, boxed, to, inline} = props

  let SFeature = null;
  if (to) {
    SFeature = Link
  } else {
    SFeature = 'div'
  }

  let STitle = styled.p`
    ${tw`text-base font-bold mb-3`}
  `
  let SContent = styled.p`
    font-size: 0.9rem;
    ${tw` m-0`}
  `
  let SFeatureContainer = styled.div`
    transition: 200ms ease-out;
    &:hover {
      transform: translate3d(0,-0.5rem,0)
    }
  `
  let SIconContainer = styled.div`
    display: inline-block;
    width: 42px; 
    height: 42px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${tw`mb-3`}
    background-color: ${theme.extend.colors.primary};
  `
  // BOXED FEATURE
  // **************
  if (boxed) {
    if (!content) {
      SFeature = styled(SFeature)`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-items: center;
        align-items: center;
        ${tw`bg-gray-lighter p-6 text-text`}
        ${tw`rounded-xl`}
        & ${STitle} {
          ${tw`text-left mb-0`}
        }
      `
      STitle = styled.p`
        ${tw`text-sm font-bold mb-3`}
      `
    } else {
      SFeature = styled(SFeature)`
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-items: flex-start;
        align-items: flex-start;
        ${tw`bg-gray-lighter p-6 text-text text-left`}
        ${tw`rounded-xl`}
        & ${STitle} {
          ${tw`text-left mb-0`}
        }
      `
      STitle = styled.p`
        ${tw`text-lg text-left font-bold mb-4`}
      `
    }
  }

  // NOT BOXED FEATURE
  // ******************
  else {
    SFeature = styled(SFeature)`
      display: flex;
      flex-direction: ${(inline) ? 'row' : 'column'};
      flex-wrap: ${(inline) ? 'nowrap' : 'wrap'};
      justify-items: left;
      align-items: ${(inline) ? 'center' : 'wrap'};
      ${tw`text-left`}
      & ${STitle} {
        ${tw`text-left`}
      }
    `
    SContent = styled.p`
      ${tw`text-left`}
      ${tw`text-sm m-0`}
      justify-items: left;
    `
  }

  let STextGroup = styled.div``

  if (inline) {
    STextGroup = styled.div`
      ${tw`ml-4`}
    `
  }
  const SIcon = styled.i`
    font-size: 18px;
    color: white;
  `

  return (
  <SFeatureContainer>
    <SFeature {...props}>
      { (icon && <SIconContainer><SIcon className={icon.props.className}/></SIconContainer>) }
      <STextGroup>
        <STitle>{title}</STitle>
        <SContent>{content}</SContent>
      </STextGroup>
    </SFeature>
  </SFeatureContainer>)
}

export default Feature

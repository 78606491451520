import React from 'react'
import tw from "tailwind.macro"
import styled from 'styled-components'
import {theme} from '../../../../tailwind.config'

const FeatureGrid = (props) => {
  const { cols, children } = props
  const SFeatureGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    > * {
      ${tw`pr-8 mb-8`}

      width: ${(cols) ? (100/cols) : (100/3)}%;
    }
    @media(max-width: ${theme.extend.screenSizes.md.max}) {
      > * {
        ${tw`pr-0 mb-4`}
        width: 100%;
      }
    }
  `
  return (
  <SFeatureGrid {...props}>
    {children}
  </SFeatureGrid>)
}

export default FeatureGrid

import React from 'react'
import classnames from "classnames";
import * as Ui from "components/ui"
import featureBgLeftImage from "images/feature-image-bg.png";

export default (props) => (
  props.items.map((elt, index) => {
    const hasPreTitle = elt.preTitle;
    const hasImage = elt.image;
    return (
      <div id={elt.id} className={classnames({
        "lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24" : true,
        "mt-20 md:mt-40": index != 0
      })}>
        <div className={classnames({'col-span-2': !elt.image})}>
          {elt.preTitle && elt.preTitle.type === Ui.Image && (elt.preTitle)}
          {elt.preTitle && elt.preTitle.type !== Ui.Image && (<p className="text-primary text-sm font-black tracking-wide uppercase">{elt.preTitle}</p>)}
          <Ui.Typography tag="h2">{elt.title}</Ui.Typography>
          <div className="text-lg my-6">
            {elt.description}
          </div>
        </div>
        {elt.image && <div className={classnames({"lg:col-start-1": index % 2 > 0, 'relative': true})}>

          <div className={classnames({
            "bg-no-repeat p-10 md:absolute": true,
            "right-0":index % 2 > 0,
            "left-0":index % 2 <= 0
          })}
               style={{'backgroundImage': 'url('+featureBgLeftImage+')', 'backgroundSize': '100%', 'maxWidth': '100%'}}>
            <div className="flex justify-center">{elt.image}</div>
          </div>
        </div>
        }
      </div>
    );
  })
);



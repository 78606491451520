import React from "react"
import classnames from "classnames"

const Section = ({ children, className, padding, hero, color }) => {
  const classes = classnames(
    className,
    {'bg-gray-lighter' : (color === 'alt')}
  );
  return (
  <section className={classes}>
    {children}
  </section>)
}

export default Section

import React from "react";
import logo from "images/capterra-logo.png";
import { Link } from "gatsby";

const badge = ({size}) => {

    if (size === 'sm') {
        return (
                <Link to="/testimonials/"
                   className="rounded-xl py-2 text-center flex items-center justify-start opacity-75" >
                    <img src={logo} alt="simplebackups-capterra" style={{'width': '49px'}}/>
                    <div className="flex items-center text-white space-x-1 mt-4 items-center" style={ {color: 'white'} }>
                        <span className="text-lg ml-6">4.9</span>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                </Link>
        )
    }

    return (
            <a href="https://www.capterra.com/p/184422/SimpleBackups/" rel="noreferrer" target="_blank" className="flex flex-col items-center py-8 px-8 text-center inline-block">
                <img src={logo} alt="simplebackups-capterra" style={{'width': '49px'}}/>

                <div className="flex items-center text-yellow space-x-1 mt-4" style={ {color: '#fe492c'} }>
                    <span>4.9</span>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                </div>
                <p className="text-gray-500 text-base">Read our reviews</p>
            </a>
    );
}

export default badge;

import React from "react";
import g2logo from "images/g2-logo.svg";
import { Link } from "gatsby";

const gtwobadge = ({size}) => {

    if (size === 'sm') {
        return (
                <Link to="/testimonials/"
                   className="rounded-xl py-2 text-center flex items-center justify-start opacity-75" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 711.29 731.26" className="w-8">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1" style={{ fill: 'white'}}>
                                <path className="cls-1 bg-white"
                                      d="M501.22,521c26.9,46.68,53.5,92.83,80.08,138.93-117.7,90.11-300.82,101-436.38-2.77C-11.08,537.65-36.72,333.73,46.8,186.94,142.86,18.1,322.62-19.24,438.36,8.14c-3.13,6.8-72.45,150.61-72.45,150.61s-5.48.36-8.58.42c-34.21,1.45-59.69,9.41-87,23.53A206.93,206.93,0,0,0,160.49,342.46a201.71,201.71,0,0,0,13.68,100.29c11,26.82,26.56,50.64,47.42,70.75,32,30.88,70.08,50,114.22,56.33,41.8,6,82,.06,119.67-18.87,14.13-7.09,26.15-14.92,40.2-25.66C497.47,524.14,499.06,522.67,501.22,521Z"/>
                                <path className="cls-1"
                                      d="M501.48,111.61c-6.83-6.72-13.16-12.92-19.46-19.16-3.76-3.72-7.38-7.59-11.23-11.22-1.38-1.31-3-3.1-3-3.1s1.31-2.78,1.87-3.92c7.37-14.79,18.92-25.6,32.62-34.2A90.65,90.65,0,0,1,553,26c22.93.45,44.25,6.16,62.24,21.54,13.28,11.35,20.09,25.75,21.29,42.94,2,29-10,51.21-33.83,66.71-14,9.12-29.1,16.17-44.24,24.52-8.35,4.61-15.49,8.66-23.65,17-7.18,8.37-7.53,16.26-7.53,16.26l108.47-.14,0,48.31H468.32s0-3.3,0-4.67c-.64-23.74,2.13-46.08,13-67.64,10-19.78,25.54-34.26,44.21-45.41,14.38-8.59,29.52-15.9,43.93-24.45,8.89-5.27,15.17-13,15.12-24.21,0-9.62-7-18.17-17-20.84C544,69.56,520,79.71,507.52,101.29,505.7,104.44,503.84,107.57,501.48,111.61Z"/>
                                <polygon className="cls-1"
                                         points="711.29 469.65 619.88 311.79 438.99 311.79 346.99 471.28 529.21 471.28 619.13 628.39 711.29 469.65"/>
                            </g>
                        </g>
                    </svg>
                    <div className="flex items-center text-white space-x-1 mt-4 items-center" style={ {color: 'white'} }>
                        <span className="text-lg ml-6">5.0</span>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                </Link>
        )
    }

    return (
        <a href="https://www.g2.com/products/simplebackups/reviews" rel="noreferrer" target="_blank" className="py-8 px-8 text-center inline-block">
            <img src={g2logo} className="w-12 m-auto" alt="g2 reviews"/>
            <div className="flex items-center text-yellow space-x-1 mt-4" style={ {color: '#fe492c'} }>
                <span>5.0</span>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
            </div>
            <p className="text-gray-500 text-base">Read our reviews</p>
        </a>
    );
}

export default gtwobadge;

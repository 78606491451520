import React, { useState } from 'react'
import find from 'lodash/find'
import { faqs } from '../../../configs/faqs'
import renderJsonLd from './faqJsonLdRenderer'

// FAQ List / Container
const Faqs = (props) => {
  let { ids, terms } = props
  let faqList;
  if (terms) {
    faqList = faqs.filter((faq) => {
      return terms.some((term) => {
        return faq.id.toLowerCase().includes(term.toLowerCase())
      })
    });
  } else {
    faqList = ids.map((id)=> find(faqs, {id}));
  }

  return (
    <>
      <div {...props} className="lg:max-w-4xl mx-auto">
        { faqList.map((element, index) => (<Faq key={index} faq={element}/>) )}
      </div>
      { renderJsonLd(faqList) }
    </>
  )
}

// Individual FAQ Component
const Faq = (props) => {
  const {title, content} = props.faq;
  const [open, setOpen] = useState(false);

  let icon = (open) ? (<i className="far fa-minus opacity-50"></i>) : (<i className="far fa-plus opacity-50"></i>);

  return (
    <div className="px-3 md:px-6 py-6 border-gray-100 border-b" onClick={() => setOpen(!open)}>
      <div className="flex justify-between items-center cursor-pointer">
        <h3 className="text-xl m-0 font-medium">{title}</h3>
        <div className="pl-3">{icon}</div>
      </div>
      { open && (<p className="mt-2 text-lg p-6" dangerouslySetInnerHTML={{ __html: content }}/>) }

    </div>
  )
}

export default Faqs

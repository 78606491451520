import React from 'react'
import tw from "tailwind.macro"
import styled from 'styled-components'
import {theme} from '../../../../tailwind.config'

const Article = (props) => {
  const {link, target, children} = props
  const SContainer = styled.a`
    ${tw`text-text`};
   text-decoration: none;
  `

  const SContent = styled.div`
    ${tw`p-8`}
    height: 100%;
    position: relative;
    border-radius: 4px;
    background: white;
    min-height: 300px;
    transition: 200ms ease-out;
    h4 {
      ${tw`text-2xl text-text`}
      line-height: 38px;
      font-weight: 300;
    }
    &:hover {
      transform: translate3d(0,-0.5rem,0);
      &:after {
        width: 40%;
      }
    }
    &:after{
      transition: 200ms ease-IN;
      position: absolute;
      display: block;
      bottom: 2rem;
      left: 2rem;
      content: '';
      height: 8px;
      width: 80px;
      background-color: ${theme.extend.colors.purple};
      border-radius: 12px;
    }

    @media(max-width: ${theme.extend.screenSizes.md.max}) {
      ${tw`pb-12`}
      min-height: 150px;
    }
  `

  return (
  <SContainer href={link} target={target}>
    <SContent>
      <h4>{children}</h4>
    </SContent>
  </SContainer>)
}

export default Article

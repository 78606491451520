import React from "react";

const Tip = (props) => {

  return (
    <p className="my-16 p-6 bg-gray-50 rounded-xl">
      <i className="fa-duotone fa-lightbulb-on mr-2 text-yellow-400"></i>
      {props.children}
    </p>
  )
}

export default Tip;
